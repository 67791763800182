<script setup lang="ts">
import { computed, ref } from "vue";
import { useRouter } from "vue-router";
import { vOnClickOutside } from "@vueuse/components";
import {
  Disclosure,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
} from "@headlessui/vue";

import { ClientName, useClientStore } from "@/stores/clientStore";
import { useNotificationStore } from "@/stores/notifications";

import type { User } from "@/interfaces/Auth";

import ComunicationSection from "../mobile/Login/ComunicationSection.vue";
import DropDownMenu from "../DropDownMenu.vue";

import WhatsappIcon from "../icons/WhatsappIcon.vue";
import PhoneIcon from "../icons/PhoneIcon.vue";
import BellIcon from "../icons/BellIcon.vue";
import CoverageIcon from "../icons/CoverageIcon.vue";
import RequestIcon from "../icons/RequestIcon.vue";
import UserCircleIcon from "../icons/UserCircleIcon.vue";
import MenuIcon from "../icons/MenuIcon.vue";
import { storeToRefs } from "pinia";

const router = useRouter();

const navigation = computed(() => {
  if (client.value !== ClientName.Rapihogar) {
    return [
      {
        name: "Mis coberturas",
        to: { name: "Products" },
        icon: "../src/assets/coverage.svg",
        alt: "Cobertura",
      },
      {
        name: "Mis asistencias",
        to: { name: "Requests" },
        icon: "../src/assets/mis_solicitudes.svg",
        alt: "Asistencias",
      },
    ];
  }
  return [
    {
      name: "Mis productos",
      to: { name: "Products" },
      icon: "../src/assets/coverage.svg",
      alt: "Producto",
    },
    {
      name: "Mis solicitudes",
      to: { name: "Requests" },
      icon: "../src/assets/mis_solicitudes.svg",
      alt: "Solicitudes",
    },
  ];
});

// const navigation = [
//   {
//     name: "Mis coberturas",
//     to: { name: "Products" },
//     icon: "../src/assets/coverage.svg",
//     alt: "Cobertura",
//   },
//   {
//     name: "Mis solicitudes",
//     to: { name: "Requests" },
//     icon: "../src/assets/mis_solicitudes.svg",
//     alt: "Solicitudes",
//   },
// ];

const clientStore = useClientStore();

const coverageText = computed(() => {
  if (client.value !== ClientName.Rapihogar) {
    return "Mis asistencias";
  }
  return "Mis solicitudes";
});

const { client, formatPhoneNumber, formatWhatsappNumber } =
  storeToRefs(clientStore);

const user: User = JSON.parse(localStorage.getItem("user") || "{}");
const fullName = computed(() => user.name + " " + user.last_name);

const phoneCall = formatPhoneNumber.value;
const whatsappCall = formatWhatsappNumber.value;

const clientTheme = computed(() => {
  if (client.value === ClientName.RioUruguaySeguros) {
    return "theme-rus md:shadow-none";
  }
  if (client.value === ClientName.Galicia) {
    return "theme-galicia md:shadow-md";
  }
  return "theme-rapi-hogar md:shadow-none";
});

const notificationStore = useNotificationStore();

const showModal = ref(false);
const dropdown = ref(false);
const dropdownRef = ref(null);
const notifications = computed(() => notificationStore.notifications);

const readNotifications = computed(() => {
  let total = 0;
  if (notifications.value) {
    notifications.value.forEach((notification) => {
      if (!notification.read) {
        total += 1;
      }
    });
  }
  return total;
});

function closeDropdown() {
  dropdown.value = false;
}

function signOut() {
  localStorage.clear();
  location.reload();
}
</script>

<template>
  <Disclosure
    as="nav"
    :class="clientTheme"
    class="nav-bar from-skin-gradient-from to-skin-gradient-to shadow-md shadow-gray-500 z-50"
  >
    <div class="nav-bar-size">
      <div class="nav-bar-position">
        <div class="">
          <router-link to="/Products">
            <img
              v-if="
                clientStore.client === 'rapihogar' ||
                clientStore.client === '' ||
                clientStore.client === 'rus'
              "
              class="logo-size"
              src="@/assets/logo_rapihogar.svg"
              alt="Rapi Hogar"
            />
            <!-- <LogoRus
              svg-color="#ffffff"
              v-if="clientStore.client === 'rus'"
              class="text-white logo-size h-7 my-0 md:h-12 md:my-1"
            /> -->
            <img
              v-if="clientStore.client === 'galicia'"
              class="logo-size w-20 md:w-[105px]"
              src="@/assets/logo_galicia.svg"
              alt="Galicia"
            />
          </router-link>
        </div>

        <div
          class="mr-0 space-x-1 flex items-center grow-0 justify-end pr-0 xl:justify-evenly gap-x-6"
        >
          <div class="hidden md:flex space-x-2 lg:space-x-6">
            <router-link
              v-for="item in navigation"
              :key="item.name"
              :to="item.to"
            >
              <span
                :class="[
                  $route.name === item.to.name
                    ? ' font-bold md:text-sm 2xl:text-base text-skin-base'
                    : '',
                ]"
                class="bar-text hover:text-skin-base mr-4"
                >{{ item.name }}</span
              >
            </router-link>
          </div>

          <div class="flex items-center gap-5">
            <a :href="phoneCall" rel="noopener noreferrer" target="_blank">
              <button
                type="button"
                class="hidden md:block bg-skin-icon-accent group rounded-full text-skin-iconalt"
              >
                <span class="sr-only">View notifications</span>
                <PhoneIcon
                  class="m-[7px] 2xl:m-[10px] group-hover:text-skin-iconalt-hover transition-all"
                  aria-hidden="true"
                />
              </button>
            </a>

            <a :href="whatsappCall" rel="noopener noreferrer" target="_blank">
              <button
                type="button"
                class="hidden md:block bg-skin-icon-accent rounded-full text-skin-iconalt group"
              >
                <WhatsappIcon
                  class="m-[6px] 2xl:m-2 group-hover:text-skin-iconalt-hover transition-all"
                  aria-hidden="true"
                />
              </button>
            </a>

            <button
              type="button"
              :disabled="!notifications.length"
              :class="[
                dropdown === true ? 'bg-skin-nav-icon-selected' : '',
                $route.name === 'Notifications'
                  ? 'bg-skin-nav-icon-selected'
                  : '',
              ]"
              class="relative bg-skin-icon-accent md:mr-9 rounded-full text-skin-iconalt group transition-all"
              @click.stop="dropdown = true"
            >
              <BellIcon
                :class="{
                  'group-hover:text-skin-iconalt': !notifications.length,
                  'text-skin-iconselected':
                    $route.name === 'Notifications' || dropdown,
                  dropdown,
                }"
                class="hidden md:block m-1 text-skin-iconalt group-hover:text-skin-iconalt-hover transition"
                aria-hidden="true"
              />

              <BellIcon
                :class="{
                  'text-skin-iconselected': $route.name === 'Notifications',
                }"
                class="md:hidden m-1 text-skin-iconalt group-hover:text-skin-iconalt-hover"
                aria-hidden="true"
                @click="router.push({ name: 'Notifications' })"
              />

              <div
                v-if="readNotifications"
                class="absolute top-[5px] right-[-1px] flex justify-center items-center bg-skin-notification-bubble min-w-[16px] h-4 rounded-full"
              >
                <p
                  class="font-montserrat text-[9px] m-1 font-semibold text-grayDetails-300"
                >
                  {{ readNotifications }}
                </p>
              </div>

              <DropDownMenu
                v-on-click-outside.bubble="closeDropdown"
                :notifications="notifications"
                class="hidden md:block absolute -left-32 top-12"
                :show-dropdown="dropdown"
                ref="dropdownRef"
              />
            </button>

            <button
              type="button"
              @click="showModal = true"
              class="md:hidden mr-5"
            >
              <MenuIcon class="text-skin-iconalt" />
            </button>

            <!-- Profile dropdown -->
            <Menu as="div" class="hidden md:flex items-center">
              <MenuButton class="flex items-center text-sm rounded-full">
                <span class="sr-only">Open user menu</span>
                <span
                  class="text-skin-user font-montserrat text-sm 2xl:text-base font-bold pr-5 capitalize"
                >
                  {{ fullName.toLowerCase() }}
                </span>
                <UserCircleIcon class="w-[40px] h-[40px] text-skin-user" />
              </MenuButton>

              <transition
                enter-active-class="transition ease-out duration-100"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95"
              >
                <div>
                  <MenuItems
                    class="origin-top-right absolute right-0 mt-5 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                  >
                    <MenuItem v-slot="{ active }">
                      <a
                        href="#"
                        @click="signOut"
                        :class="[
                          active ? 'bg-gray-100' : '',
                          'block px-4 py-2 text-sm text-gray-700',
                        ]"
                        >Cerrar sesión</a
                      >
                    </MenuItem>
                  </MenuItems>
                </div>
              </transition>
            </Menu>
          </div>
        </div>
      </div>

      <!-- Mobile menu -->
      <transition name="slide-fade">
        <div
          v-if="showModal"
          class="navbar-mobile md:hidden absolute w-[85%] bg-white left-[15%] top-0 flex flex-col justify-between"
        >
          <div>
            <div
              class="flex items-center pl-5 h-24 nav-bar from-skin-gradient-from to-skin-gradient-to"
              :class="clientTheme"
            >
              <UserCircleIcon class="w-[60px] text-skin-iconalt h-[60px]" />
              <div class="ml-1">
                <p
                  class="text-base font-montserrat font-bold capitalize text-skin-user"
                >
                  {{ fullName.toLowerCase() }}
                </p>
              </div>
            </div>
            <div class="flex flex-col gap-y-4 mt-4">
              <!-- <router-link
                v-for="item in navigation"
                :key="item.name"
                :to="item.to"
                class="border-b-[1.2px] border-grayDetails-300 pb-5"
              >
                <div class="flex gap-1 pl-3">
                  <img
                    :src="item.icon"
                    style="width: 24px; height: 24px"
                    :alt="item.alt"
                  />
                  <span
                    :class="[
                      $route.name === item.to.name
                        ? 'text-skin-highlight font-bold text-sm'
                        : 'text-grayDetails-400 font-medium text-sm',
                    ]"
                    class="bar-text mr-4"
                    >{{ item.name }}
                  </span>
                </div>
              </router-link> -->

              <router-link
                class="border-b-[1.2px] border-grayDetails-300 pb-5 flex"
                to="/Products"
              >
                <div class="flex gap-1 pl-3">
                  <CoverageIcon
                    class="text-skin-major"
                    style="width: 24px; height: 24px"
                  />
                  <span
                    :class="[
                      $route.name === 'Products'
                        ? 'text-skin-major font-bold text-sm'
                        : 'text-grayDetails-400 font-medium text-sm',
                    ]"
                    class="mr-4 text-grayDetails-400"
                    >Mis {{ $t("productos") }}</span
                  >
                </div>
              </router-link>
              <router-link class="mr-4 flex" to="/Requests">
                <div class="flex gap-1 pl-3">
                  <RequestIcon
                    class="text-skin-major"
                    style="width: 24px; height: 24px"
                  />
                  <span
                    :class="[
                      $route.name === 'Requests'
                        ? 'text-skin-major font-bold text-sm'
                        : 'text-grayDetails-400 font-medium text-sm',
                    ]"
                    class="mr-4 text-grayDetails-400"
                    >{{ coverageText }}</span
                  >
                </div>
              </router-link>
            </div>
          </div>
          <div class="flex flex-col pb-10">
            <ComunicationSection />
            <button
              class="btn btn-gray-outlined place-self-center mt-7 w-[248px]"
              @click="signOut"
            >
              Cerrar sesión
            </button>
          </div>
        </div>
      </transition>
      <!-- Mobile menu -->
    </div>
  </Disclosure>

  <transition name="fade">
    <div
      @click.self="showModal = false"
      v-if="showModal"
      class="absolute z-40 inset-0 opacity-75 bg-black"
    ></div>
  </transition>
</template>

<style scoped>
.route-link-active {
  color: red;
}
.navbar-mobile {
  height: 100vh;
}
.slide-fade-enter-active {
  transition: all 0.2s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}

.fade-enter-from {
  opacity: 0;
}
.fade-enter-to {
  opacity: 0.75;
}
.fade-enter-active {
  transition: all 0.5s ease;
}
.fade-leave-from {
  opacity: 0.75;
}
.fade-leave-to {
  opacity: 0;
}
.fade-leave-active {
  transition: all 0.25s ease;
}
</style>
