import { ref } from "vue";
import {
  getTelemedicineInfo,
  getVideoCallUrl,
  type TelemedicineDetail,
} from "../services/telemedicine.services";

const telemedicineModal = ref(false);
const videoCallModal = ref(false);
const detail = ref<TelemedicineDetail | null>(null);
const link = ref<string | null>(null);
const loadingVideoCall = ref(false);
const linkNotFound = ref(false);

export const useTelemedicine = () => {
  async function openTelemedicineModal(policyId: number, coverageId: number) {
    detail.value = await getTelemedicineInfo({ policyId, coverageId });
    telemedicineModal.value = true;
  }

  function closeTelemedicineModal() {
    telemedicineModal.value = false;
    detail.value = null;
    linkNotFound.value = false;
  }

  return {
    telemedicineModal,
    detail,
    openTelemedicineModal,
    closeTelemedicineModal,
  };
};

export const useVideoCall = () => {
  async function initVideoCall({
    policyId,
    coverageId,
  }: {
    device: "desktop" | "mobile";
    policyId: number;
    coverageId: number;
  }) {
    loadingVideoCall.value = true;
    linkNotFound.value = false;

    try {
      link.value = await getVideoCallUrl({ policyId, coverageId });

      if (link.value === null) {
        linkNotFound.value = true;
        loadingVideoCall.value = false;
        return;
      }

      telemedicineModal.value = false;
      window.open(link.value, "_blank");
    } catch (error) {
      console.log(error);
    } finally {
      loadingVideoCall.value = false;
    }
  }

  function closeVideoCallModal() {
    videoCallModal.value = false;
    link.value = null;
  }

  return {
    videoCallModal,
    loadingVideoCall,
    link,
    linkNotFound,
    initVideoCall,
    closeVideoCallModal,
  };
};
